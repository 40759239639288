<template>
  <v-snackbar v-model="snackbarVisible" :color="snackbarColor" timeout="3000">
    {{ snackbarText }}
  </v-snackbar>

  <div class="titulo">
    <div class="margem container">
      <h2>Materiais</h2>
    </div>
  </div>

  <div class="margem container">
    <div class="bloco margem">
      <div class="linha">
        <div class="grid-4">
          <div>
            <label for="termo">Termo</label>
            <input type="text" v-model="termo" id="termo" />
          </div>

          <div>
            <label for="codigo">Código</label>
            <input type="text" v-model="codigo" id="codigo" />
          </div>

          <div>
            <label>Material</label>
            <input id="produto_cod" type="text" v-model="search" @input="filterProduto" @focus="showProductList" @blur="hideProductList" placeholder="Digite para buscar" />
            <ul v-if="isProductListVisible && filteredProduto.length" class="autocomplete-list">
              <li v-for="item in filteredProduto" :key="item.id" @mousedown.prevent="selectProduto(item)">
                {{ item.desc }}
              </li>
            </ul>
          </div>

          <div>
            <label for="subCategoria">Categoria</label>
            <select v-model="subCategoria" id="subCategoria">
              <option value="" disabled selected>Selecione</option>
              <option v-for="item in categoriaLista" :key="item.id" :value="item.id">
                {{ item.displayName }}
              </option>
            </select>
          </div>

          <div>
            <label for="dtInicio">De</label>
            <input type="date" v-model="dtInicio" id="dtInicio" />
          </div>

          <div>
            <label for="dtFim">Até</label>
            <input type="date" v-model="dtFim" id="dtFim" />
          </div>

          <div>
            <label for="solicitante_id">Solicitante</label>
            <select v-model="solicitante_id" id="solicitante_id">
              <option value="" disabled selected>Selecione</option>
              <option v-for="item in solicitanteLista" :key="item.id" :value="item.id">
                {{ item.nomeCompleto }}
              </option>
            </select>
          </div>

          <div>
            <label for="setor_id">Setor</label>
            <select v-model="setor_id" id="setor_id">
              <option value="" disabled selected>Selecione</option>
              <option v-for="item in setorLista" :key="item.id" :value="item.id">
                {{ item.nome }}
              </option>
            </select>
          </div>

          <!--           <div>
            <label for="unidade">Unidade</label>
            <select v-model="unidade" id="unidade">
              <option value="" disabled selected>Selecione</option>
              <option v-for="item in filiais" :key="item.id" :value="item.id">
                {{ item.local_nome }}
              </option>
            </select>
          </div> -->
        </div>
      </div>
      <div class="linha direita">
        <div class="">
          <button style="margin-right: 10px" @click="limparCampos">Limpar</button>
          <button @click="buscar">Buscar</button>
        </div>
      </div>
      <div v-if="dadosRelatorio.length">
        <table class="tabela">
          <thead>
            <tr>
              <th class="text-center">Código</th>
              <th class="text-center">Data Solicitacao</th>
              <th class="text-center">Finalidade</th>
              <!-- <th class="text-center">Unidade</th> -->
              <th class="text-center">Setor</th>
              <th class="text-center">Solicitante</th>
              <th class="text-center">Qtd</th>
              <th class="text-center">Material</th>
              <th class="text-center">Categoria</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="item in paginatedData" :key="item.codigo">
              <tr v-for="(material, index) in item.materiais" :key="index">
                <td class="text-center">{{ item.codigo }}</td>
                <td class="text-center">{{ formatarData(item.dtCriado) }}</td>
                <td class="text-center">{{ item.finalidade }}</td>
                <!-- <td class="text-center">{{ item.unidade }}</td> -->
                <td class="text-center">{{ item.setor }}</td>
                <td class="text-center">{{ item.solicitante }}</td>
                <td class="text-center">{{ material.qt }}</td>
                <td class="text-center">{{ material.material_desc }}</td>
                <td class="text-center">{{ item.categoria }}</td>
              </tr>
            </template>
          </tbody>
        </table>
        <div class="pagination">
          <button class="acao-secundaria" :disabled="currentPage === 1" @click="prevPage">Anterior</button>
          <span>Página {{ currentPage }} de {{ totalPages }}</span>
          <button class="acao-secundaria" :disabled="currentPage === totalPages" @click="nextPage">Próxima</button>
        </div>
        <div v-if="permissoes.includes(86)" class="alinha-direita">
          <button class="" @click="buscarComExportacao">Exportar para Excel</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getPessoa, getProduto, getCategoria, getSetor, getUnidade, relatorioSolicitacao, relatorioSolicitacaoExport } from "@/services/api";
import { formatarData } from "@/utils/funcoesUtils";
import { getPermissao } from "@/services/permissaoService";

export default {
  name: "HomeMateriais",
  data() {
    return {
      snackbarVisible: false,
      snackbarColor: "success",
      snackbarText: "",
      loading: false,
      codigo: "",
      dtInicio: "",
      dtFim: "",
      solicitante_id: "",
      solicitanteLista: [],
      subCategoria: "",
      categoriaLista: [],
      setor_id: "",
      setorLista: [],
      unidade: "",
      filiais: [],
      termo: "",
      dadosRelatorio: [],
      currentPage: 1,
      itemsPerPage: 10,
      produto_cod: "",
      search: "",
      produtos: [],
      filteredProduto: [],
      isProductListVisible: false,
      permissoes: [],
    };
  },
  computed: {
    paginatedData() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.dadosRelatorio.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.dadosRelatorio.length / this.itemsPerPage);
    },
  },
  mounted() {
    this.initializeSnackbar();
    this.getPessoa();
    this.getCategoria();
    this.getUnidade();
    this.getSetor();
    this.buscaProduto();
    this.carregarPermissoes();
  },
  methods: {
    async carregarPermissoes() {
      this.permissoes = await getPermissao();
    },
    formatarData,
    async getPessoa() {
      try {
        const response = await getPessoa();
        this.solicitanteLista = response.data;
      } catch (error) {
        console.error("Erro ao carregar solicitantes:", error);
      }
    },
    async getCategoria() {
      try {
        const response = await getCategoria();
        this.categoriaLista = response.data
          .map((categoria) => ({
            id: categoria.id,
            displayName: categoria.nome,
            subCategorias: categoria.subCategoria.map((sub) => ({
              id: sub.id,
              displayName: `${sub.codigo} - ${sub.nome}`,
            })),
          }))
          .flat();
      } catch (error) {
        console.error("Erro ao buscar categorias:", error);
      }
    },
    async getUnidade() {
      try {
        const response = await getUnidade();
        this.filiais = response.data;
      } catch (error) {
        console.error("Erro ao buscar unidades:", error);
      }
    },
    async getSetor() {
      try {
        const response = await getSetor();
        this.setorLista = response.data;
      } catch (error) {
        console.error("Erro ao buscar setores:", error);
      }
    },
    async buscaProduto() {
      try {
        const response = await getProduto();
        this.produtos = response.data;
        this.filteredProduto = this.produtos;
      } catch (error) {
        console.log("Erro ao buscar produtos:", error);
      }
    },
    filterProduto() {
      const search = this.search.toLowerCase();
      this.filteredProduto = this.produtos.filter((item) => item.desc.toLowerCase().includes(search));
      this.isProductListVisible = true;
    },
    showProductList() {
      this.isProductListVisible = true;
    },
    hideProductList() {
      setTimeout(() => {
        this.isProductListVisible = false;
      }, 200);
    },
    selectProduto(item) {
      this.produto_cod = item.produto_cod;
      this.search = item.desc;
      this.isProductListVisible = false;
    },
    async buscar(params = {}) {
      if (this.search === "") {
        this.produto_cod = "";
      }

      this.loading = true;
      try {
        let response;
        if (params.gerar && params.gerar === "xlsx") {
          response = await relatorioSolicitacaoExport({
            codigo: this.codigo,
            dtInicio: this.dtInicio,
            dtFim: this.dtFim,
            produto_cod: this.produto_cod || undefined,
            solicitante_id: this.solicitante_id,
            categoria_id: this.subCategoria,
            setor_id: this.setor_id,
            unidade_id: this.unidade,
            termo: this.termo,
            ...params,
          });
          this.downloadFile(response.data, "relatorioSolicitacao.xlsx");
        } else {
          response = await relatorioSolicitacao({
            codigo: this.codigo,
            dtInicio: this.dtInicio,
            dtFim: this.dtFim,
            solicitante_id: this.solicitante_id,
            produto_cod: this.produto_cod || undefined,
            categoria_id: this.subCategoria,
            setor_id: this.setor_id,
            unidade_id: this.unidade,
            termo: this.termo,
          });
          this.dadosRelatorio = response.data;
          this.snackbarText = "Relatório gerado com sucesso!";
          this.snackbarColor = "success";
        }
      } catch (error) {
        console.error("Erro ao gerar relatório:", error);
        this.snackbarText = "Erro ao gerar relatório.";
        this.snackbarColor = "error";
      } finally {
        this.snackbarVisible = true;
        this.loading = false;
      }
    },
    async buscarComExportacao() {
      await this.buscar({ gerar: "xlsx" });
    },
    downloadFile(data, filename) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      link.click();
      window.URL.revokeObjectURL(link.href);
    },
    limparCampos() {
      this.codigo = "";
      this.dtInicio = "";
      this.dtFim = "";
      this.solicitante_id = "";
      this.produto_cod = "";
      this.subCategoria = "";
      this.setor_id = "";
      this.unidade = "";
      this.termo = "";
      this.search = "";
      this.filteredProduto = this.produtos;
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    initializeSnackbar() {
      const showSnackbar = localStorage.getItem("showSnackbar") === "true";
      if (showSnackbar) {
        this.snackbarText = localStorage.getItem("snackbarMessage");
        this.snackbarColor = localStorage.getItem("snackbarColor");
        this.snackbarVisible = true;

        localStorage.removeItem("showSnackbar");
        localStorage.removeItem("snackbarMessage");
        localStorage.removeItem("snackbarColor");
      }
    },
  },
};
</script>

<style scoped>
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination button {
  margin: 0 5px;
}

.autocomplete-list {
  border: 1px solid #ccc;
  max-height: 150px;
  overflow-y: auto;
  padding: 0;
  margin: 0;
  list-style: none;
  position: absolute;
  background-color: white;
  z-index: 10;
  width: 450px;
}

.autocomplete-list li {
  padding: 10px;
  cursor: pointer;
}

.autocomplete-list li:hover {
  background-color: #f0f0f0;
}
</style>
