<template>
  <div class="titulo">
    <div class="margem container">
      <div class="m-icone direita">
        <div class="pesquisa">
          <input type="text" v-model="search" placeholder="Pesquise Aqui" />
          <a href="#" class="icone-pesquisa" title="Pesquise"></a>
        </div>
      </div>
      <h2>Histórico de Ativos</h2>
    </div>
  </div>

  <div class="margem container">
    <div class="bloco margem">
      <div v-if="loading" class="loading">
        <div></div>
      </div>

      <table class="tabela">
        <thead>
          <tr>
            <th>Código</th>
            <th>Nome</th>
            <!-- <th>Valor</th> -->
            <th>Local Físico</th>
            <th>Unidade</th>
            <th>Responsável</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in paginatedItems" :key="item.id" @click="abrirHistorico(item)">
            <td :title="item.codigo">{{ item.codigo }}</td>
            <td :title="item.descricao">{{ item.descricao }}</td>
            <!-- <td>{{ formatarValorReal(item.valor) }}</td> -->
            <td :title="item.local_fisico?.nome || 'Não informado'">
              {{ item.local_fisico?.nome || "Não informado" }}
            </td>
            <td :title="item.unidade?.local_nome">{{ item.unidade?.local_nome }}</td>
            <td :title="item.responsavel?.name || 'Almoxarifado'">
              {{ item.responsavel?.name || "Almoxarifado" }}
            </td>
          </tr>
        </tbody>
      </table>

      <div class="pagination">
        <button @click="prevPage" :disabled="currentPage === 1" class="acao-secundaria">Anterior</button>
        <span>Página {{ currentPage }} de {{ totalPages }}</span>
        <button @click="nextPage" :disabled="currentPage === totalPages" class="acao-secundaria">Próxima</button>
      </div>

      <v-snackbar v-model="snackbarVisible" :color="snackbarColor" timeout="3000">
        {{ snackbarText }}
      </v-snackbar>

      <v-dialog v-model="movimentarDialogVisible" max-width="700px">
        <v-card>
          <v-card-title class="headline">Histórico do Ativo</v-card-title>
          <v-card-text>
            <div class="timeline-container">
              <div v-for="(historico, index) in historicoAtivos" :key="historico.id" class="timeline-card">
                <v-card class="mb-3">
                  <v-card-title class="linha">
                    <div class="coluna">{{ historico.tipo }}</div>
                    <div class="coluna alinha-direita">{{ historico.dtLog }}</div>
                  </v-card-title>
                  <v-card-text>
                    <p><strong>De:</strong> {{ historico.valor_antigo || "N/A" }}</p>
                    <p><strong>Para:</strong> {{ historico.valor_novo }}</p>
                    <p><strong>Executante:</strong> {{ historico.usuario_nome }}</p>
                    <p v-if="historico.obs"><strong>Observação:</strong> {{ historico.obs }}</p>
                  </v-card-text>
                </v-card>
                <br />
                <v-divider v-if="index < historicoAtivos.length - 1"></v-divider>
              </div>
            </div>
          </v-card-text>
          <div class="alinha-v alinha-centro">
            <button class="acao-secundaria" @click="movimentarDialogVisible = false">Fechar</button>
          </div>
          <br />
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { getListarAtivos, historicoAtivo } from "@/services/api";
import { mapActions } from "vuex";
import { formatarValorReal } from "@/utils/funcoesUtils";

export default {
  name: "HistoricoView",
  data() {
    return {
      items: [],
      search: "",
      currentPage: 1,
      itemsPerPage: 10,
      snackbarVisible: false,
      snackbarText: "",
      snackbarColor: "",
      selectedItem: null,
      movimentarDialogVisible: false,
      historicoAtivos: [],
      loading: false,
    };
  },
  computed: {
    filteredItems() {
      const searchLower = this.search.toLowerCase();
      return this.items.filter((item) => {
        return (
          (item.codigo && item.codigo.toString().toLowerCase().includes(searchLower)) ||
          (item.descricao && item.descricao.toLowerCase().includes(searchLower)) ||
          (item.valor && item.valor.toString().includes(searchLower)) ||
          (item.local_fisico?.nome && item.local_fisico.nome.toLowerCase().includes(searchLower)) ||
          (item.unidade?.local_nome && item.unidade.local_nome.toLowerCase().includes(searchLower)) ||
          (item.responsavel?.name && item.responsavel.name.toLowerCase().includes(searchLower))
        );
      });
    },
    paginatedItems() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.filteredItems.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.filteredItems.length / this.itemsPerPage);
    },
  },
  mounted() {
    this.carregarAtivos();
  },
  methods: {
    formatarValorReal,

    ...mapActions(["selectItem"]),

    async abrirHistorico(item) {
      this.selectedItem = item;
      try {
        const response = await historicoAtivo({ ativo_id: item.id });
        this.historicoAtivos = response.data.map((historico) => {
          return {
            ...historico,
          };
        });
        this.movimentarDialogVisible = true;
      } catch (error) {
        console.error("Erro ao carregar histórico do ativo:", error);
        this.showSnackbar("Erro ao carregar histórico", "error");
      }
    },

    async carregarAtivos() {
      this.loading = true;
      try {
        const response = await getListarAtivos();
        this.items = response.data;
        this.loading = false;
      } catch (error) {
        console.error("Erro ao carregar ativos:", error);
        this.loading = false;
      }
    },

    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },

    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },

    isLast(historico) {
      return this.historicoAtivos[this.historicoAtivos.length - 1] === historico;
    },

    showSnackbar(message, color) {
      this.snackbarText = message;
      this.snackbarColor = color;
      this.snackbarVisible = true;
    },

    filterItems() {
      this.currentPage = 1;
    },
    emitSearch() {
      this.$emit("update:search", this.localSearch);
    },
  },
  watch: {
    search: "filterItems",
  },
};
</script>

<style scoped>
.v-btn {
  min-width: unset;
}

.invisible-lines thead th,
.invisible-lines tbody td {
  border: none !important;
}

tr {
  cursor: pointer;
}

.timeline-container {
  position: relative;
  padding-left: 20px;
}

.timeline-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 10px;
  width: 2px;
  height: 100%;
  background-color: #e0e0e0;
}

.timeline-card {
  position: relative;
}

.timeline-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: -6px;
  width: 12px;
  height: 12px;
  background-color: #e0e0e0;
  border-radius: 50%;
  z-index: 1;
  background-color: black;
}

.tabela {
  width: 100%;
  table-layout: fixed;
}

.tabela th,
.tabela td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tabela th:nth-child(1),
.tabela td:nth-child(1) {
  width: 14%;
}
.tabela th:nth-child(2),
.tabela td:nth-child(2) {
  width: 42%;
}
.tabela th:nth-child(3),
.tabela td:nth-child(3) {
  width: 15%;
}
.tabela th:nth-child(4),
.tabela td:nth-child(4) {
  width: 14%;
}
.tabela th:nth-child(5),
.tabela td:nth-child(5) {
  width: 15%;
}

.tabela tbody tr {
  min-height: 40px;
}
</style>
