<template>
  <div class="titulo">
    <div class="margem container">
      <h2>Requisição de Material</h2>
    </div>
  </div>

  <form ref="formulario">
    <div class="margem container">
      <div class="bloco margem">
        <div class="grid-4">
          <div>
            <label>Finalidade</label>
            <select v-model="finalidadeSelecionada" @change="finalidadeChanged" required>
              <option value="" disabled selected>Selecione</option>
              <option v-for="item in finalidadeLista" :key="item.code" :value="item.code">{{ item.label }}</option>
            </select>
          </div>

          <div v-if="finalidadeSelecionada === 'Projeto'">
            <label>Projeto</label>
            <select v-model="projeto_id" required>
              <option value="" disabled selected>Selecione</option>
              <option v-for="item in projetos" :key="item.id" :value="item.id">{{ item.nome }}</option>
            </select>
          </div>

          <div v-if="finalidadeSelecionada === 'OP Específica'">
            <label>Ordem de Produção</label>
            <select v-model="op_id" required>
              <option value="" disabled selected>Selecione</option>
              <option v-for="item in ordensProducao" :key="item.id" :value="item.id">{{ item.opNum }}</option>
            </select>
          </div>

          <div>
            <label>Setor</label>
            <select v-model="setor_id" required>
              <option value="" disabled selected>Selecione</option>
              <option v-for="item in setor" :key="item.id" :value="item.id">{{ item.nome }}</option>
            </select>
          </div>

          <div>
            <label>Solicitante</label>
            <select v-model="pessoa_id" required>
              <option value="" disabled selected>Selecione</option>
              <option v-for="item in pessoa" :key="item.id" :value="item.id">{{ item.nomeCompleto }}</option>
            </select>
          </div>

          <div class="col-4">
            <label>Justificativa</label>
            <input type="text" v-model="justificativa" id="justificativa" required />
          </div>
        </div>

        <div class="linha alinha-v">
          <div class="tam-3">
            <label>Material</label>
            <input type="text" v-model="search" @input="filterProduto" @focus="showProductList" @blur="hideProductList" placeholder="Digite para buscar" />
            <ul v-if="isProductListVisible && filteredProduto.length" class="autocomplete-list">
              <li v-for="item in filteredProduto" :key="item.id" @mousedown.prevent="selectProduto(item)">
                {{ item.cod + " - " + item.desc }}
              </li>
            </ul>
          </div>

          <div style="margin-top: 27px">
            <button @click.prevent="adicionarProduto" class="acao-secundaria">Adicionar</button>
          </div>
        </div>

        <div v-if="permissoes.includes(79)" class="linha alinha-v">
          <strong><div>&nbsp; Material não encontrado?</div></strong>
          <a target="_blank" @click.prevent>
            <button class="acao-secundaria" @click="chamarCompras">Solicitar Compra</button>
          </a>
        </div>

        <br />
        <table class="tabela">
          <thead>
            <tr>
              <th>Código</th>
              <th>Descrição</th>
              <th>Unidade</th>
              <th>Estoque</th>
              <th>Quantidade Solicitada</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in materiaisSelecionados" :key="item.id">
              <td>{{ item.cod }}</td>
              <td>{{ item.desc }}</td>
              <td>{{ item.und }}</td>
              <td>{{ item.estoque }}</td>
              <td style="max-width: 50px !important">
                <input type="number" v-model="item.quantidade" min="1" />
              </td>
              <td>
                <button @click="removerProduto(item)" class="chip">Excluir</button>
              </td>
            </tr>
          </tbody>
        </table>
        <div style="margin-top: 25px">
          <button @click.prevent="enviarForm" class="acao-secundaria direita">Salvar</button>
        </div>

        <v-snackbar v-model="snackbarVisible" :color="snackbarColor" timeout="3000">
          {{ snackbarText }}
        </v-snackbar>
      </div>
    </div>
  </form>
</template>

<script>
import { consultarEstoque, getSetor, getPessoa, getProduto, gravarSolicitacao, getOrdensProducaoAtivas, getProjetosOmie } from "@/services/api";
import { getPermissao } from "@/services/permissaoService";
import { sso } from "roboflex-thalamus-sso-lib";

export default {
  name: "SolicitacaoView",

  data() {
    return {
      usuario_id: null,
      materiaisSelecionados: [],
      setor_id: "",
      pessoa_id: "",
      produto_cod: "",
      setor: [],
      pessoa: [],
      produto: [],
      projetos: [],
      ordensProducao: [],
      projeto_id: "",
      op_id: "",
      justificativa: "",
      snackbarVisible: false,
      snackbarText: "",
      snackbarColor: "",
      finalidadeSelecionada: null,
      finalidadeLista: [],
      search: "",
      filteredProduto: [],
      isProductListVisible: false,
      permissoes: [],
    };
  },

  watch: {
    finalidadeSelecionada(newValue) {
      if (newValue === "Projeto") {
        this.buscaProjetosOmie();
      } else if (newValue === "OP Específica") {
        this.buscaOrdensProducaoAtivas();
      } else {
        this.projeto_id = "";
        this.op_id = "";
      }
    },
  },

  mounted() {
    this.buscaSetor();
    this.buscaPessoa();
    this.buscaProduto();
    this.loadfinalidadeLista();
    this.carregarPermissoes();
  },

  created() {
    const usuarioLogado = sso.getUsuarioLogado();
    this.usuario_id = usuarioLogado.id;
  },

  methods: {
    async carregarPermissoes() {
      this.permissoes = await getPermissao();
    },

    async fetchSaldoEstoque(produto_cod) {
      const payload = {
        produto_cod: produto_cod,
      };

      try {
        const response = await consultarEstoque(payload);

        if (response.data && response.data.produtos && response.data.produtos.length > 0) {
          return response.data.produtos[0].nSaldo;
        } else {
          return 0;
        }
      } catch (error) {
        console.error("Erro ao buscar saldo de estoque:", error);
        throw error;
      }
    },
    chamarCompras() {
      window.open("https://compras.thalamus.ind.br/admin/?page=requisicoes/solicitacao_material", "_blank");
    },

    async validate() {
      const resultadoValidacao = await this.$refs.formulario.reportValidity();
      return resultadoValidacao;
    },

    async enviarForm() {
      const formValido = await this.validate();
      if (formValido) {
        if (this.materiaisSelecionados.length === 0) {
          this.showSnackbar("Por favor, adicione ao menos um material.", "error");
          return;
        }

        const dadosFormulario = {
          finalidade: this.finalidadeSelecionada,
          setor_id: parseInt(this.setor_id),
          solicitante_id: parseInt(this.pessoa_id),
          justificativa: this.justificativa,
          usuario_id: this.usuario_id,
          materiais: this.materiaisSelecionados.map((item) => ({
            produto_cod: item.produto_cod,
            qt: item.quantidade,
          })),
        };

        if (this.finalidadeSelecionada === "Projeto") {
          dadosFormulario.projeto_id = this.projeto_id;
        } else if (this.finalidadeSelecionada === "OP Específica") {
          dadosFormulario.op_id = this.op_id;
        }

        try {
          await gravarSolicitacao(dadosFormulario);

          localStorage.setItem("showSnackbar", "true");
          localStorage.setItem("snackbarMessage", "Solicitação criada com sucesso!");
          localStorage.setItem("snackbarColor", "success");
          this.$router.push({ name: "HomeMateriais" });
        } catch (error) {
          console.error("Erro ao enviar formulário:", error);
          this.showSnackbar("Falha ao criar solicitação.", "error");
        }
      }
    },

    loadfinalidadeLista() {
      setTimeout(() => {
        this.finalidadeLista = [
          { label: "Projeto", code: "Projeto" },
          { label: "Insumos Produção", code: "Insumos Produção" },
          { label: "OP Específica", code: "OP Específica" },
          { label: "Administrativo", code: "Administrativo" },
        ];
      }, 1000);
    },

    async buscaSetor() {
      try {
        const response = await getSetor();
        this.setor = response.data;
      } catch (error) {
        console.log(error);
      }
    },

    async buscaPessoa() {
      try {
        const response = await getPessoa();
        this.pessoa = response.data;
      } catch (error) {
        console.log(error);
      }
    },

    async buscaProduto() {
      try {
        const response = await getProduto();
        this.produto = response.data;
        this.filteredProduto = this.produto;
      } catch (error) {
        console.log(error);
      }
    },

    async buscaProjetosOmie() {
      try {
        const response = await getProjetosOmie();
        this.projetos = response.data;
      } catch (error) {
        console.error("Erro ao buscar projetos Omie:", error);
      }
    },

    async buscaOrdensProducaoAtivas() {
      try {
        const response = await getOrdensProducaoAtivas();
        this.ordensProducao = response.data;
      } catch (error) {
        console.error("Erro ao buscar ordens de produção ativas:", error);
      }
    },

    async adicionarProduto() {
      const produto = this.produto.find((p) => p.produto_cod === this.produto_cod);
      if (!produto) {
        this.showSnackbar("Produto não encontrado", "error");
        return;
      }

      if (this.materiaisSelecionados.find((item) => item.produto_cod === produto.produto_cod)) {
        this.showSnackbar("Material já consta na lista", "error");
      } else {
        try {
          const saldoEstoque = await this.fetchSaldoEstoque(produto.produto_cod);
          this.materiaisSelecionados.push({
            ...produto,
            quantidade: 1,
            estoque: saldoEstoque,
          });

          this.produto_cod = "";
          this.search = "";
          this.filteredProduto = this.produto;

          this.showSnackbar("Produto adicionado com sucesso!", "success");
        } catch (error) {
          this.showSnackbar("Erro ao buscar saldo de estoque", "error");
        }
      }
    },
    removerProduto(item) {
      const index = this.materiaisSelecionados.findIndex((i) => i.id === item.id);
      if (index !== -1) {
        this.materiaisSelecionados.splice(index, 1);
      }
    },

    filterProduto() {
      const search = this.search.toLowerCase();
      this.filteredProduto = this.produto.filter((item) => item.desc.toLowerCase().includes(search) || item.cod.toLowerCase().includes(search));
      this.isProductListVisible = true;
    },

    showProductList() {
      this.isProductListVisible = true;
    },

    hideProductList() {
      setTimeout(() => {
        this.isProductListVisible = false;
      }, 200);
    },

    selectProduto(item) {
      this.produto_cod = item.produto_cod;
      this.search = item.desc;
      this.filteredProduto = [];
      this.isProductListVisible = false;
    },

    showSnackbar(message, color) {
      this.snackbarText = message;
      this.snackbarColor = color;
      this.snackbarVisible = true;
      setTimeout(() => {
        this.snackbarVisible = false;
      }, 3000);
    },

    finalidadeChanged() {
      if (this.finalidadeSelecionada === "Projeto") {
        this.buscaProjetosOmie();
      } else if (this.finalidadeSelecionada === "OP Específica") {
        this.buscaOrdensProducaoAtivas();
      } else {
        this.projeto_id = "";
        this.op_id = "";
      }
    },
  },
};
</script>

<style scoped>
.autocomplete-list {
  border: 1px solid #ccc;
  max-height: 150px;
  overflow-y: auto;
  padding: 0;
  margin: 0;
  list-style: none;
  position: absolute;
  background-color: white;
  z-index: 10;
}

.autocomplete-list li {
  padding: 10px;
  cursor: pointer;
}

.autocomplete-list li:hover {
  background-color: #f0f0f0;
}
</style>
