<template>
  <v-dialog v-model="loginDialogVisible" max-width="400">
    <v-card>
      <v-card-title class="headline">Confirmação de Associação</v-card-title>
      <v-card-text>
        <div>
          <label for="unidadeSelecionada">Selecionar Unidade</label>
          <select id="unidadeSelecionada" v-model="unidadeSelecionada" required>
            <option v-for="unidade in unidades" :key="unidade.id" :value="unidade.id">{{ unidade.local_nome }}</option>
          </select>
        </div>

        <div>
          <label>Local Físico</label>
          <select v-model="localFisico_id" class="mt-2" required>
            <option v-for="local in locaisFiltrados" :key="local.id" :value="local.id">
              {{ local.nome }}
            </option>
          </select>
        </div>

        <br />
        <div>
          <label for="loginUsuario">Usuário</label>
          <input id="loginUsuario" v-model="loginUsuario" type="text" required @keyup.enter="movimentarAtivo" />
        </div>
        <br />
        <div>
          <label for="loginSenha">Senha</label>
          <input id="loginSenha" v-model="loginSenha" type="password" required @keyup.enter="movimentarAtivo" />
        </div>
      </v-card-text>
      <v-spacer></v-spacer>
      <div class="alinha-v alinha-centro">
        <button style="margin-right: 10px" class="acao-secundaria" @click="closeDialog">Cancelar</button>
        <button class="acao-secundaria" @click="movimentarAtivo">Confirmar</button>
      </div>
      <br />
    </v-card>
  </v-dialog>

  <v-dialog v-model="detalhesDialogVisible" max-width="600">
    <v-card>
      <v-card-title class="headline">Detalhes do Ativo</v-card-title>
      <div v-if="loadingModal" class="loading">
        <div></div>
      </div>
      <div v-else>
        <v-card-text>
          <div><strong>Código:</strong> {{ selectedItem?.codigo || "N/A" }}</div>
          <div><strong>Nome:</strong> {{ selectedItem?.descricao || "N/A" }}</div>
          <div><strong>Valor:</strong> {{ formatarValorReal(selectedItem?.valor) || "N/A" }}</div>
          <div><strong>Unidade:</strong> {{ selectedItem?.unidade?.local_nome || "N/A" }}</div>
          <div><strong>Local Físico:</strong> {{ selectedItem?.local_fisico?.nome || "N/A" }}</div>
          <div><strong>Responsável:</strong> Almoxarifado</div>
          <div><strong>Marca:</strong> {{ selectedItem?.marca || "N/A" }}</div>
          <div><strong>Modelo:</strong> {{ selectedItem?.modelo || "N/A" }}</div>
          <div><strong>IMEI:</strong> {{ selectedItem?.imei || "N/A" }}</div>
          <div><strong>Código de Barras:</strong> {{ selectedItem?.codigoBarras || "N/A" }}</div>
          <div><strong>Número de Série:</strong> {{ selectedItem?.nrSerie || "N/A" }}</div>
          <div><strong>Fornecedor:</strong> {{ selectedItem?.fornecedor?.nome || "N/A" }}</div>
          <div><strong>Vida Útil:</strong> {{ selectedItem?.vidaUtil || "N/A" }}</div>
          <div><strong>Data Fim da Garantia:</strong> {{ selectedItem?.dtFinalGarantia || "N/A" }}</div>
          <div><strong>Complemento:</strong> {{ selectedItem?.complemento || "N/A" }}</div>

          <div><strong>Anexos:</strong></div>
          <ul>
            <li v-for="anexo in selectedItem?.anexos ?? []" :key="anexo.id">
              <a :href="getAnexoUrl(anexo.path)" target="_blank">{{ anexo.nome || "Sem nome" }}</a>
            </li>
          </ul>

          <div class="alinha-v alinha-centro">
            <img v-if="selectedItem?.qrcode_base64" :src="selectedItem.qrcode_base64" alt="QR Code" />
          </div>
        </v-card-text>

        <div class="alinha-v alinha-centro">
          <button class="acao-secundaria" @click="detalhesDialogVisible = false">Fechar</button>
        </div>
      </div>
      <br />
    </v-card>
  </v-dialog>

  <div class="titulo">
    <div class="margem container">
      <div class="m-icone direita">
        <div class="pesquisa">
          <input type="text" v-model="search" placeholder="Pesquise aqui" />
          <a href="#" class="icone-pesquisa" title="Pesquise"></a>
        </div>
      </div>
      <h2>Ativos não Movimentados</h2>
    </div>
  </div>

  <div class="margem container">
    <div class="bloco margem">
      <div v-if="loading" class="loading">
        <div></div>
      </div>
      <table class="tabela">
        <thead>
          <tr>
            <th>Código</th>
            <th>Nome</th>
            <th>Valor</th>
            <th>Unidade</th>
            <th>Responsável</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in paginatedItems" :key="item.id" @click="mostrarDetalhes(item)">
            <td :title="item.codigo">{{ item.codigo }}</td>
            <td :title="item.descricao">{{ item.descricao }}</td>
            <td :title="formatarValorReal(item.valor)">{{ formatarValorReal(item.valor) }}</td>
            <td :title="item.unidade?.local_nome">{{ item.unidade?.local_nome }}</td>
            <td :title="'Almoxarifado'">Almoxarifado</td>
            <td :title="item.status">
              <div v-if="item.status !== 'Em manutenção'">{{ item.status }}</div>
              <div v-else>{{ item.protocolo_codigo }}</div>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="pagination">
        <button @click="prevPage" :disabled="currentPage === 1" class="acao-secundaria">Anterior</button>
        <span>Página {{ currentPage }} de {{ totalPages }}</span>
        <button @click="nextPage" :disabled="currentPage === totalPages" class="acao-secundaria">Próxima</button>
      </div>
    </div>
  </div>

  <v-snackbar v-model="snackbarVisible" :color="snackbarColor" timeout="3000">
    {{ snackbarText }}
  </v-snackbar>
</template>

<script>
import { getBuscarAtivo, getListarAtivos, getLocalFisico, movimentarAtivo, getUnidade } from "@/services/api";
import { formatarValorReal } from "@/utils/funcoesUtils";
import { sso } from "roboflex-thalamus-sso-lib";
export default {
  name: "AtivosOciososView",
  data() {
    return {
      locais: [],
      loading: false,
      loadingModal: false,
      localFisico_id: "",
      items: [],
      search: "",
      currentPage: 1,
      itemsPerPage: 10,
      snackbarVisible: false,
      snackbarText: "",
      snackbarColor: "",
      loginDialogVisible: false,
      loginUsuario: "",
      loginSenha: "",
      selectedItem: null,
      detalhesDialogVisible: false,
      unidadeSelecionada: null,
      unidades: [],
      usuario_id: null,
    };
  },
  computed: {
    qrCodeUrl() {
      return this.selectedItem ? this.selectedItem.qrcode_base64 : "";
    },
    locaisFiltrados() {
      return this.locais.filter((local) => local.local_id === this.unidadeSelecionada);
    },
    filteredItems() {
      const searchLower = this.search.toLowerCase();
      return this.items.filter((item) => {
        return (
          (item.codigo && item.codigo.toString().toLowerCase().includes(searchLower)) ||
          (item.descricao && item.descricao.toLowerCase().includes(searchLower)) ||
          (item.valor && item.valor.toString().includes(searchLower)) ||
          (item.unidade?.local_nome && item.unidade.local_nome.toLowerCase().includes(searchLower)) ||
          (item.status && item.status.toLowerCase().includes(searchLower))
        );
      });
    },
    paginatedItems() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.filteredItems.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.filteredItems.length / this.itemsPerPage);
    },
  },
  mounted() {
    this.carregarAtivos();
    this.getLocais();
    this.carregarUnidades();
  },
  created() {
    const usuarioLogado = sso.getUsuarioLogado();
    this.usuario_id = usuarioLogado.id;
  },

  methods: {
    async carregarUnidades() {
      try {
        const response = await getUnidade();
        this.unidades = response.data;
      } catch (error) {
        console.error("Erro ao carregar unidades:", error);
      }
    },
    formatarValorReal,
    abrirModal(item) {
      this.selectedItem = item;
      this.loginDialogVisible = true;
    },
    async mostrarDetalhes(item) {
      try {
        this.loadingModal = true;
        this.detalhesDialogVisible = true;
        const response = await getBuscarAtivo({ id: item.id });
        this.selectedItem = response.data;
        this.loadingModal = false;
      } catch (error) {
        this.loadingModal = false;
        console.error("Erro ao buscar ativo:", error);
        this.showSnackbar("Erro ao carregar detalhes do ativo", "error");
      }
    },

    async movimentarAtivo() {
      if (this.loginUsuario && this.loginSenha && this.localFisico_id) {
        try {
          const payload = {
            usuario_id: this.usuario_id,
            ativo_id: this.selectedItem.id,
            localFisico_id: this.localFisico_id,
            email: this.loginUsuario,
            password: this.loginSenha,
          };
          await movimentarAtivo(payload);
          this.showSnackbar("Ativo movimentado com sucesso", "success");
          this.carregarAtivos();
        } catch (error) {
          console.error("Erro ao movimentar ativo:", error);
          this.showSnackbar("Erro ao movimentar ativo", "error");
        } finally {
          this.closeDialog();
        }
      } else {
        this.showSnackbar("Todos os campos são obrigatórios.", "error");
      }
    },
    getAnexoUrl(path) {
      return `${process.env.VUE_APP_ROOT_STORAGE}${path}`;
    },
    closeDialog() {
      this.loginDialogVisible = false;
      this.localFisico_id = "";
      this.loginUsuario = "";
      this.loginSenha = "";
      this.selectedItem = null;
    },
    async carregarAtivos() {
      this.loading = true;
      try {
        const response = await getListarAtivos();
        this.items = response.data.filter((item) => item.responsavel_id === null);
      } catch (error) {
        console.error("Erro ao carregar ativos:", error);
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    async getLocais() {
      try {
        const response = await getLocalFisico();
        this.locais = response.data;
      } catch (error) {
        console.error("Erro ao carregar locais físicos:", error);
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },

    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    showSnackbar(message, color) {
      this.snackbarText = message;
      this.snackbarColor = color;
      this.snackbarVisible = true;
      setTimeout(() => {
        this.snackbarVisible = false;
      }, 3000);
    },
  },
};
</script>

<style scoped>
.tabela {
  width: 100%;
  table-layout: fixed;
}

.tabela th,
.tabela td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tabela th:nth-child(1),
.tabela td:nth-child(1) {
  width: 14%;
}
.tabela th:nth-child(2),
.tabela td:nth-child(2) {
  width: 40%;
}
.tabela th:nth-child(3),
.tabela td:nth-child(3) {
  width: 10%;
}
.tabela th:nth-child(4),
.tabela td:nth-child(4) {
  width: 14%;
}
.tabela th:nth-child(5),
.tabela td:nth-child(5) {
  width: 12%;
}
.tabela th:nth-child(6),
.tabela td:nth-child(6) {
  width: 10%;
}

.tabela tbody tr {
  min-height: 40px;
}
</style>
