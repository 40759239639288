import { api } from "roboflex-thalamus-request-handler/dist";

export const getSetor = () => {
  return api.get("setor");
};

export const getPessoa = () => {
  return api.get("pessoa");
};

export const getProduto = () => {
  return api.get("produto");
};

export const consultarEstoque = (dados) => {
  return api.post("/omie/estoque/buscar", dados);
};

// relatório solicitacao

export const relatorioSolicitacao = (dados) => {
  return api.post("/solicitacao/relatorio", dados);
};

export const relatorioSolicitacaoExport = async (params) => {
  return await api.post("/solicitacao/relatorio", params, {
    responseType: "blob",
  });
};

// Ordem de Serviço

export const gravarOrdemServico = (dados) => {
  return api.post("protocolo/gravar", dados);
};

// Solicitação Material

export const gravarSolicitacao = (dados) => {
  return api.post("solicitacao-material/gravar", dados);
};

export const listarMateriais = (dados) => {
  return api.post("solicitacao-material/listar", dados);
};

export const detalharSolicitacao = (dados) => {
  return api.post("solicitacao-material/buscar", dados);
};

export const getOrdensProducaoAtivas = () => {
  return api.get("ordem-producao/ativas");
};

export const getProjetosOmie = () => {
  return api.get("projetos/omie/listar");
};

// Aprovação material

export const alterarStatusMaterial = (dados) => {
  return api.post("solicitacao-material-status/atualizar", dados);
};

// Status

export const getStatus = () => {
  return api.get("status-ativo/listar");
};

export const gravarStatus = (dados) => {
  return api.post("status-ativo/gravar", dados);
};

export const atualizarStatus = (id, dados) => {
  return api.put(`status-ativo/atualizar/${id}`, dados);
};

export const excluirStatus = (id) => {
  return api.delete(`status-ativo/excluir/${id}`);
};

// Local Fisico

export const getLocalFisico = () => {
  return api.get("local-fisico/listar");
};

export const getLocalFisicoUnidade = (id) => {
  return api.get(`local-fisico/buscar/unidade/${id}`);
};

export const gravarLocalFisico = (dados) => {
  return api.post("local-fisico/gravar", dados);
};

export const atualizarLocalFisico = (id, dados) => {
  return api.put(`local-fisico/atualizar/${id}`, dados);
};

export const excluirLocalFisico = (id) => {
  return api.delete(`local-fisico/excluir/${id}`);
};

// Unidade

export const getUnidade = () => {
  return api.get("local");
};

export const gravarUnidade = (dados) => {
  return api.post("local", dados);
};

export const atualizarUnidade = (id, dados) => {
  return api.put(`local/${id}`, dados);
};

export const excluirUnidade = (id) => {
  return api.delete(`local/${id}`);
};

// Motivo alerta

export const getMotivoAlerta = () => {
  return api.get("motivo-alerta/listar");
};

export const gravarMotivoAlerta = (dados) => {
  return api.post("motivo-alerta/gravar", dados);
};

export const atualizarMotivoAlerta = (id, dados) => {
  return api.put(`motivo-alerta/atualizar/${id}`, dados);
};

export const excluirMotivoAlerta = (id) => {
  return api.delete(`motivo-alerta/excluir/${id}`);
};

// Categoria

export const getCategoria = () => {
  return api.get("categoria/listar");
};

export const getCategoriaSuperior = () => {
  return api.get("categoria/superior");
};

export const gravarCategoria = (dados) => {
  return api.post("categoria/gravar", dados);
};

export const atualizarCategoria = (id, dados) => {
  return api.put(`categoria/atualizar/${id}`, dados);
};

export const excluirCategoria = (id) => {
  return api.delete(`categoria/excluir/${id}`);
};

// Ativos

export const gerarQrcodePdf = (payload) => {
  return api.post("/ativo/pdf/qrcode", payload, { responseType: "blob" });
};

export const gravarAtivo = (dados) => {
  return api.post("/ativos/gravar", dados, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const getListarAtivos = () => {
  return api.get("/ativos/listar");
};

export const getAtivosUsuario = (id) => {
  return api.get(`/ativos/listar/usuario/${id}`);
};

export const getBuscarAtivo = (dados) => {
  return api.post("/ativos/buscar", dados);
};

export const atualizarAtivo = (dados) => {
  return api.post("/ativos/atualizar", dados, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const historicoAtivo = (dados) => {
  return api.post("/ativo/historico", dados);
};

export const relatorioAtivo = (dados) => {
  return api.post("/ativos/relatorio", dados);
};

export const relatorioExport = async (params) => {
  return await api.post("/ativos/relatorio", params, {
    responseType: "blob",
  });
};

export const excluirAnexo = (dados) => {
  return api.post("/ativos/anexo/excluir", dados);
};

export const adicionarAnexo = (dados) => {
  return api.post("/ativos/anexo/adicionar", dados);
};
//Movimentar ativo

export const movimentarAtivo = (dados) => {
  return api.post("/ativo/movimentar", dados);
};

// Fornecedor

export const getFornecedor = () => {
  return api.get("/listar/fornecedor");
};

//Minha área

export const gravarNotificacaoAtivo = (dados) => {
  return api.post("/ativo/notificacao/gravar", dados);
};

//Alertas

export const getlistarAlertas = (dados) => {
  return api.post("/ativo/notificacao/listar", dados);
};

export const atualizarAlertas = (dados) => {
  return api.put("/ativo/notificacao/atualizar", dados);
};

// Usuário

export const getlistarUsuarios = () => {
  return api.get("/usuario");
};

export default api;
